@import "../../assets/sass/variables";

.attachment {
  .ant-upload .ant-upload-btn {
    border-radius: 3px;
  }

  .ant-upload-wrapper .ant-upload-list {
    display: flex;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-top: none;
    border-radius: 3px;
    cursor: pointer;
    transition: border-color 0.3s;
    padding: 10px;
  }

  .ant-upload-list-item {
    background-color: #FFFFFF;
    height: 100%;
    margin: 0px !important;
  }

  &-content {
    display: flex;
    gap: 20px;

    &-text {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-top: 8px;
    }
  }

  &-uploadbtn {
    display: flex;
    justify-content: space-between;
  }
}

.dashboard-table {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .drawer-container {
    width: 40%;
  }

  .table-actions {
    display: flex;
    justify-content: space-between;

    .search-document {
      width: 200px;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;

      input {
        padding-right: 30px;
      }

      .search-icon {
        position: absolute;
        right: 10px;

        &:hover {
          path {
            fill: $darkBlue !important;
          }
        }
      }
    }

    .header-actions{
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  td {
    cursor: default;
  }

  .row {
    &-name {
      cursor: pointer;
      color: $darkBlue !important; 
      display: flex;
      gap: 7px;
      align-items: center;
      
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #3232A6;
      }

      &:hover { 
        color: $linkColor !important; 
      }
    }
  }

  thead {
    padding-right: 8px;
  }

  &-preview {
    color: #3232A6;
    display: flex;
    justify-content: center;

    &-icon {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .actions-tile {
    width: 100px;
  }

  &-page-count {
    text-align: center !important;
    width: 150px !important;
  }

  &-status {
    text-align: center !important;
    max-width: 180px !important;
  }

  &-small {
    text-align: center !important;
    max-width: 100px !important;
  }

  &-mid-size {
    text-align: center !important;
    max-width: 250px !important;
  }

  .table-doc-id {
    min-width: 30% !important;
    color: $darkBlue !important;
    cursor: pointer;
  }

  .ant-table-tbody {
    max-height: calc(100vh - 290px) !important;
  }

  &__content {
    display: flex;
    gap: 10px;

  }

  .status {
    border: 1px solid #f5f9ff;
    border-radius: 3px;
    box-shadow: 0 2px 15px #0000000d;
    display: inline-block;
    font-family: Font-Medium, sans-serif;
    font-size: 14px;
    padding: 6px 10px;
  
    &-not_reviewed {
      color: $baseTextColor;
    }
  
    &-reviewed, &-ready {
      color: $green;
    }
  
    &-failed, &-deleted {
      color: $red;
    }
  
    &-processing {
      color: $primaryColor;
    }

  }

  .text-wrapper {
    word-break: break-word;  
    white-space: normal;
  }
  
  .highlighted-row {
    background-color: #e6e6f9 !important;  // Light blue background
    transition: background-color 0.3s;    // Smooth transition effect
  }
  
  .action {
    width: 12%;
    text-align: end;
  }

  .ant-picker-input .ant-picker-input-active{
    &:hover {
      color: #e6e6f9 !important;
    }
  }
  .retry-container{
    display: flex;
    align-items: center;
    gap: 5px;
    color: red;
    cursor: pointer;
  }
  .action-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

