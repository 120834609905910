@use "../../../assets/sass/variables";

.donut {
  &-container {
    flex: 1;
    border: 1px solid #E7ECF4;
    padding: 30px;
    border-radius: 10px;
    max-height: 511px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);

    .g2-html-annotation {
      display: none !important;
    }
  }
}

.statistics {
  border: 1px solid #E7ECF4;
  padding: 30px;
  border-radius: 10px;
  height: 511px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);

  .ant-statistic-title {
    font-size: 17px;
    color: #635f6a;
    margin-bottom: 0px;
  }

  .ant-progress-inner {
    border-radius: 0px;
  }

  .ant-progress-bg {
    border-radius: 0px;
  }
}

.summary {
  &-container {
    display: flex;
    margin-bottom: 10px;
    gap: 30px;
    flex-direction: column;

    &.row-layout {
      flex-direction: row;
      width: 100%; 
    }

    &.other-copilots {
      width: 50%; 
    }
  }

  &-title {
    padding-bottom: 10px;
    font-size: 20px;
  }
}

.service {
  &-status {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &-datePicker {
    display: flex;
    gap: 10px;
    white-space: nowrap;
    min-width: 400px;

    &-title {
      display: flex;
      align-items: center;
    }
  }

  &-horizontal {
    border-top: 1px solid #475569;
    opacity: 30%;
    margin: 15px 20px;
    width: 55%;
  }
}

.wrapper {
  margin-bottom: 10px;
}

.extractionData {
  height: 380px;

  .ant-table-cell {
    padding: 8px 24px !important;
  }

  &-data {
    display: grid;
    gap: 10px;
    margin-bottom: 15px;
  }

  &-table {
    &-count {
      text-align: center !important;
    }
  }
}

.processing-trend {
  flex: 1;
  border: 1px solid #E7ECF4;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
}

.height {
  height: 375px;
}

// the processingtrend should align in row for copilots name cca and a&g as we are not rendering the other two divs for cca and a&g